.character-container {
  background-color: var(--grey2);
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 16px 16px;
}

.character-container .description {
  font-size: 16px;
  color: var(--white50);
  text-align: center;
  line-height: 1.3;
}

.wide-button.chat-button {
  max-width: fit-content;
  padding-left: 18px;
  padding-right: 24px;
  height: 48px;
  border-radius: 16px;
  position: relative;
  transform: translateY(50%);
}

.tags-container {
  margin-bottom: 28px;
  width: 100%;
  padding: 0px 20px;
}

.tags-container.mobile {
  overflow-x: scroll;
}

.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--grey4);
  border-radius: 60px;
  width: 100%;
  height: 34px;
  color: var(--white);
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
}
