.centered-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.centered-container-vertical {
  align-items: center;
  height: calc(100svh - 96px);
}
