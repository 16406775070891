.wide-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--grey1);
    color: var(--white);
    border-radius: 90px;
    height: 42px;
    width: 100%;
}

.wide-button:hover {
    background-color: var(--white);
    color: var(--grey0);
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.wide-button.accent {
    background-color: var(--orange1);
}

.wide-button.accent:hover {
    background-color: var(--white);
    color: var(--orange1);
}

.wide-button.light {
    background-color: var(--grey2);
}

.wide-button.light:hover {
    background-color: var(--white);
    color: var(--grey0);
}
