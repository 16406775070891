.file-preview {
  width: 100px;
  height: 100px ;
  border: 1px solid grey;
  border-radius: 10px;
  max-width: 100%;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.file-preview:hover {
  border: 1px solid #1890ff;
}

.file-preview-image {
  object-fit: cover;
}

.file-preview-other {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-preview-other-contained {
  font-size: 10px;
  word-wrap: break-word;
  max-width: 100%;
  text-align: center;
  padding: 10px;
}
