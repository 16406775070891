.auth-form {
    width: calc(100% - 40px);
    padding: 20px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form .divider {
    width: 100%;
    height: 2px;
    border-bottom: solid 2px var(--grey3);
    margin: 18px 0;
}

.auth-form .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 34px;
    font-size: 22px;
    font-weight: bold;
    color: var(--white);
}

.auth-form .description {
    margin-top: 8px;
    margin-bottom: 20px;
    color: var(--grey6);
    font-size: 14px;
    text-align: center;
}

.auth-form .terms {
    margin-top: 8px;
    color: var(--grey5);
    font-size: 12px;
    text-align: center;
}

.auth-form .switch {
    margin-top: 24px;
    color: var(--grey5);
    font-size: 14px;
    text-align: center;
}

.auth-form button {
    width: 100%;
    border-radius: 90px;
    height: 50px;
    box-shadow: none;
    background-color: var(--grey1);
    color: var(--white);
}

.auth-form button:hover:not(:disabled) {
    background-color: var(--white) !important;
    color: var(--grey0) !important;
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.auth-form button:disabled {
    background-color: var(--grey3);
    color: var(--white);
    border: 0;
}

.auth-form .link {
    color: var(--white);
}

.auth-form .link:hover {
    text-decoration: underline;
}

.auth-form button.light {
    background-color: var(--grey2) !important;
}

.auth-form button:hover:not(:disabled).light {
    background-color: var(--white) !important;
}
