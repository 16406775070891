.navigation-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: var(--white);
}

.navigation-button:hover {
    color: var(--white);
}

.navigation-button.active {
    cursor: default;
}

.navigation-button .icon-circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--grey3);
    color: var(--white);
    border: 0;
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.navigation-button.large .icon-circle {
    min-width: 48px;
    width: 48px;
    height: 48px;
}

.navigation-button:not(:disabled):hover .icon-circle {
    background-color: var(--white);
    color: var(--grey0);
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.navigation-button.active .icon-circle {
    background-color: var(--orange1);
    box-shadow: 0px 0px 20px 0px #FE5C0399;
    color: var(--white);
}

.navigation-button:not(:disabled):hover.active .icon-circle {
    background-color: var(--orange1);
    box-shadow: 0px 0px 20px 0px #FE5C0399;
    color: var(--white);
}

.navigation-button .ant-avatar {
    background-color: rgba(0, 0, 0, 0);
}
