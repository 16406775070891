.explore-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.explore-list .character-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin: 10px;
}

.explore-list .character-item .character-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 12px;
    width: 300px;
    aspect-ratio: 4 / 5;
    background-color: #151515;
    border-radius: 19px;
    background-size: cover;
    background-origin: border-box;
}

.explore-list .character-item:hover .character-image {
    border: solid 2px var(--white);
    padding-bottom: 10px;
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.explore-list .character-item .character-image .character-name {
    color: var(--white);
    font-size: 24px;
    font-weight: bold;
}

.explore-list .character-item .character-image .voice {
    color: var(--grey6);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
}
