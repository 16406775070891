.character-highlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 420px;
  aspect-ratio: 4 / 5;
  background-size: cover;
  border-radius: 36px;
  padding: 14px;
}

.character-highlight .top-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.character-highlight .tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  color: var(--grey1);
  background-color: var(--white);
  font-size: 12px;
  padding: 6px 8px;
  width: fit-content;
}

.character-highlight .tag.accent {
  color: var(--white);
  background-color: var(--orange1);
}

.character-highlight .audio-player-container {
  width: 100%;
  background-color: var(--white);
  padding: 6px;
  border-radius: 37px;
}
