.account-container {
    width: 100%;
    padding: 15px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-container .inner-wrapper {
    width: 100%;
    max-width: 500px;
}

.account-container .inner-wrapper .content {
    width: 100%;
    max-width: 500px;
    border-radius: 46px;
    background-color: var(--grey2);
    color: var(--white);
    padding: 36px 20px 12px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
