.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--orange4);
    color: var(--orange1);
    border-radius: 20px;
    width: 82px;
    height: 82px;
    font-size: 12px;
    font-weight: bold;
}

.feature-price {
    color: var(--orange4);
    font-size: 12px;
}
