.title-icon {
    display: flex;
    flex-direction: row;
    color: var(--white);
    font-size: 26px;
    font-weight: bold;
}

.title-icon .title {
    font-size: 26px;
    font-weight: bold;
    color: var(--white);
}

.title-icon.center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
