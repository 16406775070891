.index-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index-container .image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 36px;
}

.index-container .title {
  font-size: 24px;
  font-weight: bold;
  color: var(--white);
  text-align: center;
}
