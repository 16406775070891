body {
    background-color: var(--grey1);
    margin: 0;
    letter-spacing: -0.03rem;
}

@font-face {
    font-family: 'MatterSQTRIAL';
    src: local('MatterSQTRIAL-Regular'), url(./fonts/MatterSQTRIAL-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MatterSQTRIAL';
    src: local('MatterSQTRIAL-Medium'), url(./fonts/MatterSQTRIAL-Medium.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
}


.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.button-circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--grey4);
    color: var(--white);
    border: 0;
    padding: 0;
    cursor: pointer;
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.button-circle:not(:disabled):hover {
    background-color: var(--white);
    color: var(--grey2);
}

.button-circle:not(:disabled):focused {
    background-color: var(--grey4);
    color: var(--white);
}

.button-menu {
    background-color: var(--white);
    border: 0;
    padding: 0;
    cursor: pointer;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    color: var(--grey1)
}

.button-menu:not(:disabled):hover {
    background-color: var(--white);
    color: var(--grey2);
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.ant-layout {
    background: rgba(0, 0, 0, 0);
}

.ant-layout-header {
    height: 96px;
    padding: 16px;
    background-color: var(--grey2);
    border-radius: 0 0 16px 16px;
    position: fixed;
    z-index: 1;
    width: 100%;
    border-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;
    text-align: center;
}

.ant-layout-footer {
    height: 96px;
    padding: 16px;
    background-color: var(--grey2);
    position: fixed;
    z-index: 1;
    width: 100%;
    bottom: 0;
    text-align: center;
}

.layout-content-offset {
    overflow-y: auto;
    overscroll-behavior: none;
}

.inner-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ant-layout-sider {
    position: fixed !important;
    height: 100vh;
    background-color: var(--grey0) !important;
    color: var(--white);
    padding: 32px 20px;
}

.ant-drawer .ant-drawer-header {
    border: 0;
}

.ant-drawer .ant-drawer-content {
    background-color: var(--grey2);
    color: var(--white);
    border-radius: 16px 0 0 16px;
}

.ant-drawer .ant-drawer-content .button-circle {
    position: absolute;
    top: 20px;
    right: 20px;
    transform-origin: center center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    ::-webkit-scrollbar {
        display: none;
    }

    .ant-drawer .ant-drawer-content {
        border-radius: 16px 16px 0 0;
    }

    iframe {
        margin: 0 !important; /* for google sign in */
    }
}

.ant-drawer .ant-drawer-body {
    padding: 0px;
}

.ant-input, .ant-select-selector {
    color: var(--white) !important;
    background-color: var(--grey5) !important;
    border-color: var(--grey5) !important;
    font-size: 14px !important;
    border-radius: 16px !important;
    height: 40px !important;
    padding: 0 20px 0 20px !important;
}

.ant-input::placeholder, .ant-select-selection-placeholder {
    color: #7B7C7D !important;
}

.ant-select-single {
    height: 40px;
}

.ant-form-item-explain-error {
    padding: 2px;
    margin-left: 20px;
    font-size: 12px;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-form-item-required {
    color: var(--white) !important;
    font-size: 14px !important;
    font-weight: bold;
}

.ant-form-item-label {
    padding: 0 !important;
}

.ant-spin-nested-loading {
    width: 100%;
}

/*
.ant-input-affix-wrapper {
    border: 1px solid rgba(255, 255, 255, 0.10);
}

.ant-input-affix-wrapper-focused {
    border: 1px solid #1D1D1F;
}
*/





.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header-container .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--white);
    font-size: 22px;
    font-weight: bold;
}

.header-container .title.absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ant-avatar {
    background-color: var(--grey2);
}

.header-container .ant-avatar {
    width: 48px;
    height: 48px;
}

.header-container .ant-avatar img {
    width: 48px;
    height: 48px;
}

.header-container .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    width: 122px;
    height: 32px;
    background-color: var(--grey1);
    color: var(--white);
    font-size: 16px;
    user-select: none;
}


::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background: var(--grey4);
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--grey5);
}

:root {
    --grey0: #191A1C;
    --grey1: #1F2123;
    --grey2: #2A2C2E;
    --grey3: #363738;
    --grey4: #4C4D4F;
    --grey5: #555658;
    --grey6: #BCBCBD;
    --orange1: #FF5C02;
    --orange2: #FF8642;
    --orange3: #FFE1D1;
    --orange4: #FFECE3;
    --white: #FFFFFF;
    --white30: #B3B3B3;
    --white50: #FFFFFF80;
    --white70: #FFFFFFB3;
}
