.chat-list-container {
    padding: 0;
}

.chat-list {
    margin-bottom: 20px;
    width: 100%;
    padding: 0px 10px;
}

.chat-item {
    width: 100%;
    padding: 10px 16px 10px 16px;
    border-radius: 16px;
    cursor: pointer;
}

.chat-item:hover {
    background-color: var(--grey3);
}

.chat-list .character-name {
    color: var(--white);
    font-size: 16px;
}

.chat-list .message {
    color: var(--grey6);
    font-size: 14px;
}

.no-chats {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
    color: var(--grey6);
}
