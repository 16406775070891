.file-uploader-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.file-uploader-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ant-upload, .ant-upload-list-picture-card-container {
  width: 260px !important;
  height: 260px !important;
  max-width: 100% !important;
}

.ant-upload-draggable-list-item {
  height: 100%;
}

.file-uploader-wrapper {
  max-width: 100%;
}

.drop-over-left {
  border-right: 2px dashed white;
  padding-right: 10px;
}

.drop-over-right {
  border-left: 2px dashed white;
  padding-left: 10px;
}

