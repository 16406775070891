.plans-outer-container {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 32px;
}

.plans-inner-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  max-width: 100%;
}

.plans-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans-container {
  margin-bottom: 28px;
  width: 100%;
  padding: 0px 20px;
  justify-content: center;
}

.plan {
  border-radius: 16px;
  background-color: var(--grey2);
  border: 2px solid var(--grey2);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  user-select: none;
  padding: 20px;
  padding-right: 120px;
}

.plan:hover {
  background-color: var(--grey1);
  border: 2px solid var(--white);
}

.plan.active {
  border: 2px solid var(--white);
  cursor: default;
}

.plan.active:hover {
  background-color: var(--grey2);
  border: 2px solid var(--white);
}

.plan .price {
  display: flex;
  flex-direction: row;
  color: var(--white);
  align-items: end;
}

.plans-outer-container .confirm-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--grey1);
  border-radius: 90px;
  margin: 0px 20px;
  padding: 15px;
  width: calc(100% - 40px);
  max-width: 200px;
  border: 0;
  color: var(--white);
  font-size: 16px;
}

.plans-outer-container .confirm-button.active {
  cursor: pointer;
  background-color: var(--white);
  color: var(--grey0);
  box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.plans-outer-container .confirm-button.active:hover {
  background-color: var(--orange1);
  color: var(--white);
  box-shadow: 0px 0px 20px 0px #FE5C0399;
}
