.active-plan-container {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  color: var(--white);
}

.active-plan-inner-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  max-width: 100%;
}

.active-plan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-plan-wrapper .cancel-subscription {
  text-decoration: underline;
  cursor: pointer;
}
