.mini-audio-player-button {
  border-radius: 50%;
  border: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mini-audio-player-button.loading {
  cursor: default;
}
