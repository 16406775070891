.features-container {
    /*width: 100%;*/
    /*justify-content: center;*/

    width: fit-content;
    max-width: 100%;
}

.features-container.mobile {
    overflow-x: scroll;
}
