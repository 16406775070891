.menu-drawer-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: 0;
    padding: 0;
    color: var(--white);
    font-size: 18px;
    cursor: pointer;
}