.message-box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 22px;
  position: relative;
}

.message-box.non-voice {
  max-width: 340px;
}

.message-box.voice {
  width: 260px;
}

.message-box.text-or-voice {
  padding: 14px;
}

.message-box.left {
  margin-right: auto;     
}

.message-box.left.text-or-voice {
  color: var(--white);
  background-color: var(--orange1);
}

.message-box.right {
  margin-left: auto;
}

.message-box.right.text-or-voice {
  color: var(--white);
  background-color: var(--grey3);;
}

.message-box .time {
  user-select: none;
  font-size: 14px;
  margin-top: 10px;
  width: fit-content;
}

.message-box.left .time {
  color: #ffdccc;
}

.message-box.right .time {
  color: #a6a6a6;  
}

.message-box .media {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 400px;
  max-width: 85vw;
  border-radius: 22px;
}

.message-box .notch {
  position: absolute;
  bottom: -1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--orange1);
}

.message-box.left .notch {
  left: -1px;
  background-color: var(--orange1);
}

.message-box.right .notch {
  right: -1px;
  background-color: var(--grey3);;
}

.message-box .center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
}

.message-box .bottom-center {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  pointer-events: none;
}

.message-box .top-right {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}

/* for play video icon */
.message-box .icon svg {
  fill: var(--white) !important;
}

.message-box .unlock {
  padding: 10px 15px 10px 15px;
  border-radius: 70px;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  font-weight: normal;
  white-space: nowrap;
  user-select: none;
  font-weight: bold;
}

.message-box .price {
  background-color: var(--white);
}

.message-box .instructions {
  background-color: #FFFFFF80;
}

.message-box .duration {
  background-color: #FFFFFF80;
  font-size: 12px;
}

.message-box .pointer {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.message-box .mini-audio-player-wrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
}
