.classic-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
}

.classic-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #000000cc;
  width: 100%;
  height: 100%;
}

.classic-modal-content {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.classic-modal-content .button-circle {
  position: absolute;
  top: 20px;
  right: 20px;
  transform-origin: center center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
