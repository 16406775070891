.button-response-type {
    width: 30px;
    height: 32px;
    border: 0;
    background-color: var(--white30);
    color: var(--grey2);
    cursor: pointer;
}

.button-response-type:hover {
    background-color: var(--white50);
}

.button-response-type.active {
    background-color: var(--white);
    box-shadow: 0px 0px 20px 5px #FFFFFF80;
    cursor: default;
}

.button-response-type.left {
    border-radius: 20px 4px 4px 20px;
}

.button-response-type.right {
    border-radius: 4px 30px 30px 4px;
}
