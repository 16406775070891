.avatar-uploader {
  object-fit: cover;
  cursor: pointer;
  width: fit-content;
}

.avatar-uploader.no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
}

.upload-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--white);
  color: var(--grey1);
}