.button-auth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    width: 90px;
    height: 32px;
    border: 0;
    background-color: var(--grey1);
    color: var(--white);
    cursor: pointer;
    font-size: 16px;
}

.button-auth:hover:not(:disabled) {
    background-color: var(--white) !important;
    color: var(--grey0) !important;
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}
