.credits-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.credits-container {
  width: 100%;
  background-color: var(--grey2);
  padding: 16px;
}

.balance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--grey3);
  padding: 22px;
  border-radius: 46px;
}

.balance-container .credits-title {
  color: var(--white50);
  font-size: 16px;
  margin-bottom: 24px;
}

.balance-container .credits-value {
  color: var(--white);
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.credits-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background-color: var(--grey2);
  color: var(--white);
  font-size: 14px;
  border-radius: 0 0 16px 16px;
}
